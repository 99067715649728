import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled, thd, Grid, Row, Col } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

import { withSettings } from 'containers/WebApp/SettingsContext';

const FooterWrapper = styled.footer`
  border-top: 10px solid #fff;
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    ${up(
      'md',
      css`
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      color: #fff;
      font-weight: bold;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const LinksWrapper = styled.div`
  background-color: ${thd('primary', 'gold')};
`;

const Footer = ({ siteName }) => (
  <FooterWrapper>
    <LinksWrapper>
      <Grid>
        <Row justifyContent="center">
          <Col className="text--center">
            <ul>
              <li>
                <Link to="/sitemap">Sitemap</Link>
              </li>
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>

              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Grid>
    </LinksWrapper>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
};

Footer.defaultProps = {
  siteName: 'Lori Program Default',
};

export default withSettings(Footer);
